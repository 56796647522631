var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-4"},[_c('WkSelect',{ref:"expense_select",attrs:{"label":"Rodzaj kosztu","placeholder":"Wybierz rodzaj kosztu","items":_vm.expenseSelectItems,"rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    return true;
                }
            ],"show-asterisk":""},model:{value:(_vm.local_data.expense_type),callback:function ($$v) {_vm.$set(_vm.local_data, "expense_type", $$v)},expression:"local_data.expense_type"}})],1),_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"expense_name",attrs:{"type":"text","label":"Nazwa wydatku","placeholder":"np. czynsz","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    else if (v.length > 63)
                        { return 'Maksymalna długość nazwy przedmiotu to 63 znaki'; }
                    return true;
                }
            ],"show-asterisk":""},model:{value:(_vm.local_data.name),callback:function ($$v) {_vm.$set(_vm.local_data, "name", $$v)},expression:"local_data.name"}})],1),_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"expense_value",attrs:{"type":"text","label":"Szacunkowa wartość","placeholder":"np. 5 000 zł","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v.length > 31) { return 'Maksymalna długość kwoty to 31 znaków'; }
                    if (!this$1.$store.state.REGEX_IS_MONEY.test(v))
                        { return 'Proszę podać prawidłową kwotę'; }
                    return true;
                }
            ],"show-asterisk":""},model:{value:(_vm.local_data.value),callback:function ($$v) {_vm.$set(_vm.local_data, "value", $$v)},expression:"local_data.value"}})],1),_c('div',{staticClass:"mb-4"},[_c('WkTextarea',{ref:"expense_description",attrs:{"type":"text","label":"Uwagi","placeholder":"Napisz coś tutaj","rows":8,"counter":"511","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v.length > 511)
                        { return 'Maksymalna długość nazwy przedmiotu to 511 znaków'; }
                    return true;
                }
            ],"show-asterisk":""},model:{value:(_vm.local_data.description),callback:function ($$v) {_vm.$set(_vm.local_data, "description", $$v)},expression:"local_data.description"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }