//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        label: {
            type: String
        },
        showAsterisk: {
            type: Boolean,
            deafult: false
        },
        value: {
            type: [String, Number]
        },
        rules: {
            type: Array,
            default() {
                return [];
            }
        },
        errorMsg: {
            type: String,
            default: "Proszę podać prawidłową datę"
        },
        prependRules: {
            type: Array,
            default() {
                return [];
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        id() {
            this.handleId();
        },
        value() {
            this.handleValue();
        }
    },

    computed: {
        hasLabelSlot() {
            return !!this.$slots.label;
        },
        validations() {
            return [
                ...this.prependRules,
                v => {
                    if (v === false) return this.errorMsg;
                    return true;
                }
            ].concat(this.rules);
        }
    },

    data() {
        return {
            local_id: "",
            day: "",
            month: "",
            year: "",
            internal_value: false,
            error_state: false,
            focused_state: false
        };
    },

    methods: {
        setValue(e, value, pad = 0) {
            let current_value = e.target.value;
            if (current_value !== "" && pad > 0) {
                current_value = current_value.pad(pad);
            }

            this[value] = current_value;

            // if (e.target.value === "") return;

            const date = `${this.year}-${this.month}-${this.day}`;

            if (this.testDate(date)) {
                this.internal_value = date;
                this.$emit("change", date);
            } else {
                this.internal_value = false;
            }
            this.$emit("input", date);
            this.$nextTick(this.validate);
        },

        getDaysInMonth(year, month) {
            return new Date(year, month, 0).getDate();
        },

        testDate(date) {
            if (!/^[1-9]\d{3}-((0[1-9])|(1[0-2]))-([0-2][0-9]|3[0-1])$/.test(date)) {
                return false;
            }

            if (this.day > this.getDaysInMonth(this.year, this.month) || this.day === "00") {
                return false;
            }

            return true;
        },

        handleValue() {
            if (this.testDate(this.value)) {
                this.day = this.value.slice(-2);
                this.month = this.value.slice(5, 7);
                this.year = this.value.slice(0, 4);
                this.internal_value = `${this.year}-${this.month}-${this.day}`;
            }
        },

        validate() {
            return this.$refs["wk-input"].validate();
        },

        resetValidation() {
            return this.$refs["wk-input"].resetValidation();
        },

        handleId() {
            if (this.id) {
                this.local_id = this.id;
            } else {
                this.local_id = "input_" + (Date.now() + Math.random() * 100000).toString(16);
            }
        }
    },
    created() {
        this.handleValue();
    }
};
