//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpRadioButton from "@/components/PpRadioButton.vue";

export default {
    components: {
        PpRadioButton
    },

    props: {
        value: {
            type: Boolean
        }
    },

    data() {
        return {
            v: this.value
        };
    },

    methods: {
        handleValue() {
            this.$emit("input", this.v);
        }
    }
};
