//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpDataField from "@/components/PpDataField.vue";
import RecurringExpensesForm from "@/components/RecurringExpenses/RecurringExpensesForm.vue";
import PpComboDateInput from "../components/PpComboDateInput.vue";
import PpDialog from "../components/PpDialog.vue";
import RecurringExpensesHasExpenses from "../components/RecurringExpenses/RecurringExpensesHasExpenses.vue";

export default {
    components: {
        PpDataField,
        RecurringExpensesForm,
        PpComboDateInput,
        PpDialog,
        RecurringExpensesHasExpenses
    },

    data() {
        return {
            expenses: [],
            expenses_loading: false,
            expenses_loaded: false,
            expenses_current_page: 1,
            expenses_number_of_pages: 1,

            has_expenses: true,
            display_expenses: false,

            confirm_dialog: false,
            confirm_loading: false,

            create_dialog: false,
            create_loading: false,

            skip_dialog: false,

            update_loading: false,

            delete_loading: false
        };
    },

    computed: {
        app() {
            return this.$store.getters["application/getApplication"];
        }
    },

    methods: {
        openCreateModal() {
            this.create_dialog = true;
        },
        closeCreateModal() {
            this.create_dialog = false;
        },

        onSubmit() {
            if (this.has_expenses && this.display_expenses === false) {
                this.display_expenses = true;
            } else if (this.has_expenses && this.display_expenses) {
                this.openConfirmDataModal();
            } else {
                this.openSkipModal();
            }
        },

        async addExpense() {
            if (this.create_loading === true) return;

            if (this.$refs.cfe.validate() !== true) return;

            this.create_loading = true;
            try {
                await this.$axios.$post(`/recurring-expenses`, {
                    ...this.$refs.cfe.saveData(),
                    application: this.app._id
                });
                this.$message({
                    type: "success",
                    title: "Wydatek cykliczny został dodany",
                    msg: "Znajdziesz go u dołu listy"
                });

                this.closeCreateModal();
            } catch (error) {
                console.error(error);
            }
            this.create_loading = false;
        },

        async updateExpense(index) {
            if (this.update_loading === true) return;

            if (this.$refs["refe_" + index][0].validate() !== true) return;

            this.update_loading = true;

            try {
                const data = this.$refs["refe_" + index][0].saveData();
                this.$axios.$put(`/recurring-expenses/${data._id}`, data);
                this.$refs["df_" + index][0].closeEditModal();
            } catch (error) {
                console.error(error);
            }

            this.update_loading = false;
        },

        deleteExpense(id) {
            if (this.delete_loading === true) return;

            this.delete_loading = true;

            try {
                this.$axios.$delete(`/recurring-expenses/${id}`);
            } catch (error) {
                console.error(error);
            }

            this.delete_loading = false;
        },

        async sendExpensesData() {
            if (this.confirm_loading === true) return;

            if (this.expenses.length < 1 && this.has_expenses) {
                return this.$message({
                    type: "error",
                    title: "Niekompletne dane",
                    msg: "Dodaj co najmniej jeden wydatek cykliczny"
                });
            }

            this.confirm_loading = true;

            try {
                await this.$axios.$post(
                    `/applications/${this.$store.getters["application/getApplication"]._id}/confirm-recurring-expenses`
                );

                this.$message({
                    type: "success",
                    title: "Sukces",
                    msg: "Wydatki cykliczne zostały zatwierdzone"
                });

                this.$router.push({
                    name: "debts"
                });

                this.closeConfirmDataModal();
                this.closeSkipModal();
            } catch (error) {
                console.error(error);
            }
            this.confirm_loading = false;
        },

        getExpenseInfo(expense_details) {
            return `${expense_details.value} • ${
                this.$store.state.constants.RECURRING_EXPENSE_TYPES_LABELS[
                    expense_details.expense_type
                ]
            } ${expense_details.description && `• ${expense_details.description}`}`;
        },

        handleNewRecurringExpense(pc) {
            this.expenses.push(pc);
        },

        handleUpdateRecurringExpense(pc) {
            const ix = this.expenses.findIndex(asset => asset._id === pc._id);

            if (ix === -1) return;

            const item_copy = { ...this.expenses[ix] };

            this.expenses.splice(ix, 1, {
                ...item_copy,
                ...pc
            });
        },

        handleDeleteRecurringExpense(pc) {
            const ix = this.expenses.findIndex(asset => asset._id === pc._id);

            if (ix === -1) return;

            this.expenses.splice(ix, 1);
        },

        onIntersectionObserverIntersect() {
            this.expenses_current_page++;
            this.fetchData();
        },

        async fetchData() {
            if (this.expenses_loading === true) return;
            this.expenses_loading = true;
            try {
                const data = await this.$axios.$get(
                    `/recurring-expenses/?application=${this.app._id}&page=${this.expenses_current_page}&items_per_page=32`
                );
                this.expenses = this.expenses.concat(data.recurring_expenses);
                this.expenses_current_page = data.pagination.current_page;
                this.expenses_number_of_pages = data.pagination.number_of_pages;
                this.expenses_loaded = true;
            } catch (error) {
                console.error(error);
            }
            this.expenses_loading = false;
        },
        openConfirmDataModal() {
            this.confirm_dialog = true;
        },

        closeConfirmDataModal() {
            this.confirm_dialog = false;
        },

        openSkipModal() {
            this.skip_dialog = true;
        },

        closeSkipModal() {
            this.skip_dialog = false;
        }
    },

    mounted() {
        if (!this.$store.state.initial_layout_set) return;
        this.fetchData();
        this.$io.on("RecurringExpense.created", this.handleNewRecurringExpense);
        this.$io.on("RecurringExpense.updated", this.handleUpdateRecurringExpense);
        this.$io.on("RecurringExpense.deleted", this.handleDeleteRecurringExpense);
    },

    beforeDestroy() {
        this.$io.off("RecurringExpense.created", this.handleNewRecurringExpense);
        this.$io.off("RecurringExpense.updated", this.handleUpdateRecurringExpense);
        this.$io.off("RecurringExpense.deleted", this.handleDeleteRecurringExpense);
    },

    metaInfo: {
        title: "Wydatki cykliczne"
    }
};
