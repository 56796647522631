//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        label: {
            type: String
        },
        value: {
            type: Object
        }
    },
    data() {
        return {
            local_data: {
                name: "",
                value: "",
                description: "",
                expense_type: ""
            }
        };
    },

    computed: {
        expenseSelectItems() {
            const c = Object.entries(this.$store.state.constants.RECURRING_EXPENSE_TYPES).map(
                expense => ({
                    text: this.$store.state.constants.RECURRING_EXPENSE_TYPES_LABELS[expense[1]],
                    value: expense[1]
                })
            );
            return c;
        }
    },

    watch: {
        value() {
            this.handleValue();
        }
    },
    methods: {
        saveData() {
            return this.local_data;
        },

        handleValue() {
            if (this.value) {
                this.local_data = { ...this.value };
            }
        },

        validate() {
            const validation_inputs = [
                this.$refs.expense_name.validate(),
                this.$refs.expense_value.validate(),
                this.$refs.expense_description.validate(),
                this.$refs.expense_select.validate()
            ];
            if (validation_inputs.indexOf(false) !== -1) {
                return false;
            }
            return true;
        }
    },

    mounted() {
        this.handleValue();
    }
};
