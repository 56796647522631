//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: [String, Number, Boolean],
            default: false
        },
        trueValue: {
            type: [String, Number, Boolean],
            default: true
        },
        falseValue: {
            type: [String, Number, Boolean],
            default: false
        },
        label: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            focus: false
        };
    },
    computed: {
        isSelected() {
            return this.value === this.trueValue;
        },
        hasLabelSlot() {
            return !!this.$slots.label;
        }
    },
    methods: {
        toggle() {
            if (this.isSelected) return;
            let nv = this.trueValue;
            this.$emit("input", nv);
            this.$emit("change", nv);
        }
    }
};
