//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        title: {
            type: String
        },
        deleteTitle: {
            type: String,
            default: "Usuwanie"
        },
        deleteContentModalText: {
            type: String,
            default: "Czy napewno usunąć?"
        },
        actionModalButtonText: {
            type: String,
            default: "Edytuj"
        },
        actionDeleteButtonText: {
            type: String,
            default: "Usuń"
        },
        confirmMainModalButtonText: {
            type: String,
            default: "Zapisz"
        },
        cancelMainModalButtonText: {
            type: String,
            default: "Anuluj"
        },
        confirmDeleteModalButtonText: {
            type: String,
            default: "Usuń"
        },
        cancelDeleteModalButtonText: {
            type: String,
            default: "Anuluj"
        }
    },
    data() {
        return {
            is_edit_display_modal: false,
            is_delete_display_modal: false
        };
    },

    methods: {
        openEditModal() {
            this.is_edit_display_modal = true;
        },
        closeEditModal() {
            this.is_edit_display_modal = false;
        },
        openDeleteModal() {
            this.is_delete_display_modal = true;
        },

        deleteData() {
            this.$emit("delete");
        },
        updateEditedData() {
            this.$emit("update");
        }
    }
};
